.Sidebar {
    width: 310px;
    display: flex;
    flex-direction: column;

    @include respond-points(0, 1300) {
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 100%;
        left: 0;
        width: calc(100%);
        height: 100%;
        z-index: 100;
        background-color: #fff;
        transition: top .2s, opacity .2s;

        &.active {
            top: 51px;
            opacity: 1;
            pointer-events: unset;
            overflow-y: auto;
        }
    }


    .sidebar-main-stick {
        display: flex;
        flex-direction: column;

        @include respond(1301) {
            position: sticky;
            top: 20px;
            margin-bottom: 20px;
        }

        .sidebar-main {
            display: flex;
            flex-direction: column;
            border: 1px solid $grey-color;
            border-radius: 5px;

            @include respond-points(0, 1300) {
                border: none;
                border-radius: 0;
            }
        }

        .saving-status {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin: 20px 0 0 0;

            @include respond-points(0, 1300) {
                display: none;
            }

            .error-message{
                padding: 24px;
                border-radius: 5px;
                width: 100%;
                text-align: left;
                margin: 0 auto;
                &.success{
                    background-color: rgba(28, 173, 0, 0.1);
                    border: 1px solid #1CAD00;
                }
                &.progress{
                    background-color: rgba(0, 138, 207, 0.1);
                    border: 1px solid #008ACF;
                }
                &.error{
                    background-color: rgba(231, 64, 16, 0.1);
                    border: 1px solid #E74010;
                }
                .status{
                    display: flex;
                    align-items: center;
                    .saving-icon {
                        width: 20px;
                        height: 20px;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-left: 8px;
        
                        &.progress {
                            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTggMTgiPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiBzdHlsZT0iZmlsbDpub25lIi8+PC9jbGlwUGF0aD48L2RlZnM+PHRpdGxlPmxvYWQtYjwvdGl0bGU+PGcgc3R5bGU9ImNsaXAtcGF0aDp1cmwoI2EpIj48cGF0aCBkPSJNOSwxLjVBNy41LDcuNSwwLDEsMCwxNi41LDkiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiMwMDhhY2Y7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDoycHgiPgo8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGR1cj0iMXMiIHZhbHVlcz0iMCA5IDk7MzYwIDkgOSIga2V5VGltZXM9IjA7MSI+PC9hbmltYXRlVHJhbnNmb3JtPgo8L3BhdGg+PC9nPjwvc3ZnPg==');
                        }
        
                        &.success {
                            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTggMTgiPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiBzdHlsZT0iZmlsbDpub25lIi8+PC9jbGlwUGF0aD48L2RlZnM+PGcgc3R5bGU9ImNsaXAtcGF0aDp1cmwoI2EpIj48cGF0aCBkPSJNOSwxNi41QTcuNSw3LjUsMCwxLDAsMS41LDksNy41LDcuNSwwLDAsMCw5LDE2LjVaIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMWRhZDAwO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4Ii8+PHBhdGggZD0iTTEyLjUsNi4zOCw3LjY5LDExLjE5LDUuNSw5IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMWRhZDAwO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4Ii8+PC9nPjwvc3ZnPg==');
                        }
        
                        &.error {
                            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzU4M183NDc5KSI+CjxwYXRoIGQ9Ik05IDE2LjVDMTMuMTQyMSAxNi41IDE2LjUgMTMuMTQyMSAxNi41IDlDMTYuNSA0Ljg1Nzg2IDEzLjE0MjEgMS41IDkgMS41QzQuODU3ODYgMS41IDEuNSA0Ljg1Nzg2IDEuNSA5QzEuNSAxMy4xNDIxIDQuODU3ODYgMTYuNSA5IDE2LjVaIiBzdHJva2U9IiNFNzQwMTEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik05LjAwNzgxIDZMOS4wMDc4MSA5IiBzdHJva2U9IiNFNzQwMTEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik05LjAwNzgxIDEyTDkuMDAwMzEgMTIiIHN0cm9rZT0iI0U3NDAxMSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8L3N2Zz4K');
                        }
                    }
                    .title{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 26px;
                        margin-left: 0px;
                        color: $darkblue-color; 
                    }
                }
                .message{
                    font-size: 16px;
                    line-height: 26px;
                    margin-left: 0px;
                    color: $darkblue-color;
                }
            }
        }
    }

}