@mixin respond($breakpoint) {
    @media (min-width: $breakpoint + px) {
        @content;
    }
}
  
@mixin respond-points($min, $max) {
    @media (min-width: $min + px) and (max-width: $max + px) {
        @content;
    }
}
