// colors
$primary-color: #ef7d00;
$primary-color-hover: #d06c00;
$primary-color-dark: #804300;
$primary-color-light: #EF7D0026;
$sec-color: #008acf;
$sec-color-hover: #0075af;
$sec-color-light: #ECF7FE;
$darkblue-color: #001935;
$grey-color: #D9D9D9;
$grey-color-light: #f5f5f5;
$grey-color-dark: #888888;
$red-color: #E74011;

$font: 'Archivo', sans-serif;
$tr: 150ms;