.Footer {
    display: flex;
    padding: 16px 40px;
    background-color: $darkblue-color;
    margin: 0 -40px;
    margin-top: auto;

    @include respond-points(0, 1300) {
        padding: 10px 20px;
        margin: 0 -20px;
    }

    .container {
        display: flex;
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;

        span {
            margin-left: auto;
        }
    }
}