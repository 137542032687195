.FieldRepeaterMultipleItems {

    .field-title {
        .field-toggle {
            width: 24px;
            height: 24px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI1NV80ODYxKSI+CjxwYXRoIGQ9Ik00LjkyODkzIDE5LjA3MTFDOC44MzQxOCAyMi45NzYzIDE1LjE2NTggMjIuOTc2MyAxOS4wNzExIDE5LjA3MTFDMjIuOTc2MyAxNS4xNjU4IDIyLjk3NjMgOC44MzQxNiAxOS4wNzExIDQuOTI4OTJDMTUuMTY1OCAxLjAyMzY3IDguODM0MTggMS4wMjM2NyA0LjkyODkzIDQuOTI4OTJDMS4wMjM2OSA4LjgzNDE2IDEuMDIzNjkgMTUuMTY1OCA0LjkyODkzIDE5LjA3MTFaIiBzdHJva2U9IiMwMDE5MzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNC44Mjg0IDkuMTcxNTdMOS4xNzE1MSAxNC44Mjg0IiBzdHJva2U9IiMwMDE5MzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik05LjE3MTYzIDkuMTcxNTdMMTQuODI4NSAxNC44Mjg0IiBzdHJva2U9IiMwMDE5MzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjU1XzQ4NjEiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: auto;
            cursor: pointer;
            transform: rotate(45deg);
            transition: $tr;

            &.open {
                transform: rotate(0);
            }
        }
    }

    .form-inputs {
        display: flex;
        flex-direction: column;

        .inputs-row {
            display: flex;
            gap: 25px 15px;
            margin-top: 20px;
            position: relative;

            @include respond-points(0, 767) {
                padding: 15px;
                background-color: $grey-color-light;
                border-radius: 3px;
                flex-direction: column;
                gap: 10px;
            }

            .row-number {
                width: 30px;
                height: 30px;
                background-color: $grey-color-light;
                border: 1px solid $grey-color;
                color: #888;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                font-size: 14px;
                line-height: normal;
                border-radius: 50%;
                margin-top: auto;
                margin-bottom: 8px;
            }

            .input-item {

                &.spread {
                    flex: 1;
                }

                &.radio {
                    margin-top: 32px;

                    @include respond-points(0, 767) {
                        margin-top: 15px;
                    }
                }

                &.number-field {
                    max-width: 105px;

                    @include respond-points(0, 767) {
                        max-width: none;
                    }
                }

                &.hours-field {
                    max-width: 70px;

                    @include respond-points(0, 767) {
                        max-width: none;
                    }
                }

                &.total-field {
                    max-width: 140px;

                    @include respond-points(0, 767) {
                        max-width: none;
                    }
                }
            }

            .remove-row {
                width: 18px;
                height: 18px;
                cursor: pointer;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIwMV8yNTA1MSkiPgo8cGF0aCBkPSJNMy42OTY3IDE0LjMwMzNDNi42MjU2MyAxNy4yMzIzIDExLjM3NDQgMTcuMjMyMyAxNC4zMDMzIDE0LjMwMzNDMTcuMjMyMiAxMS4zNzQ0IDE3LjIzMjIgNi42MjU2NyAxNC4zMDMzIDMuNjk2NzNDMTEuMzc0NCAwLjc2NzgwMSA2LjYyNTYzIDAuNzY3ODAxIDMuNjk2NyAzLjY5NjczQzAuNzY3NzY3IDYuNjI1NjcgMC43Njc3NjcgMTEuMzc0NCAzLjY5NjcgMTQuMzAzM1oiIHN0cm9rZT0iIzg4ODg4OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTEuMTIxMyA2Ljg3ODY2TDYuODc4NyAxMS4xMjEzIiBzdHJva2U9IiM4ODg4ODgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuODc4NjYgNi44Nzg2NkwxMS4xMjEzIDExLjEyMTMiIHN0cm9rZT0iIzg4ODg4OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzIwMV8yNTA1MSI+CjxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                margin-top: auto;
                margin-bottom: 14px;

                &.hidden {
                    opacity: 0;
                    pointer-events: none;
                }

                @include respond-points(0, 767) {
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 15px;
                    right: 15px;
                }
            }
        }
    }

    .add-row {
        margin-top: 25px;
    }

    .form-zero-costs-toggle {
        display: flex;
        flex-direction: row;
        margin: 25px 0;
        cursor: pointer;
        width: fit-content;
        font-size: 16px;
        line-height: 1.4;

        @include respond-points(0, 767) {
            font-size: 15px;
            line-height: 22px;
        }

        .input-checkbox {
            margin-top: 1px;
        }
    }
}