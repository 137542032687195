.FieldProjects {
    display: flex;
    flex-direction: column;


    .projects-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 20px 0;

        @include respond-points(0, 767) {
            flex-direction: column;
        }

        .project-item {
            width: calc(50% - 10px);
            padding: 20px 58px;
            border-radius: 5px;
            border: 1px solid $grey-color;
            cursor: pointer;
            background-size: 20px 20px;
            background-position: left 20px center;
            background-repeat: no-repeat;
            position: relative;

            @include respond-points(0, 767) {
                width: 100%;
            }

            &:hover {
                border-color: $sec-color;

                .project-name {
                    text-decoration: underline;
                }
            }

            &.valid {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzMxNl83MjgxKSI+CjxwYXRoIGQ9Ik05IDE2LjVDMTMuMTQyMSAxNi41IDE2LjUgMTMuMTQyMSAxNi41IDlDMTYuNSA0Ljg1Nzg2IDEzLjE0MjEgMS41IDkgMS41QzQuODU3ODYgMS41IDEuNSA0Ljg1Nzg2IDEuNSA5QzEuNSAxMy4xNDIxIDQuODU3ODYgMTYuNSA5IDE2LjVaIiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjMDA4QUNGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIuNSA2LjM3NUw3LjY4NzUgMTEuMTg3NUw1LjUgOSIgc3Ryb2tlPSIjMDA4QUNGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzMxNl83MjgxIj4KPHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
            }

            &.warning {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzMxNl83Mjg4KSI+CjxwYXRoIGQ9Ik05IDE2LjVDMTMuMTQyMSAxNi41IDE2LjUgMTMuMTQyMSAxNi41IDlDMTYuNSA0Ljg1Nzg2IDEzLjE0MjEgMS41IDkgMS41QzQuODU3ODYgMS41IDEuNSA0Ljg1Nzg2IDEuNSA5QzEuNSAxMy4xNDIxIDQuODU3ODYgMTYuNSA5IDE2LjVaIiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjRUY3RDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOS4wMDc4MSA2TDkuMDA3ODEgOSIgc3Ryb2tlPSIjRUY3RDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOS4wMDc4MSAxMkw5LjAwMDMxIDEyIiBzdHJva2U9IiNFRjdEMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMzE2XzcyODgiPgo8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
            }


            .project-themas {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: $grey-color-dark;
                word-break: break-word;
            }

            .project-name {
                color: $darkblue-color;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                word-break: break-word;
            }

            .project-remove {
                width: 18px;
                height: 18px;
                position: absolute;
                top: calc(50% - 9px);
                right: 20px;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIwMV8yNTA1MSkiPgo8cGF0aCBkPSJNMy42OTY3IDE0LjMwMzNDNi42MjU2MyAxNy4yMzIzIDExLjM3NDQgMTcuMjMyMyAxNC4zMDMzIDE0LjMwMzNDMTcuMjMyMiAxMS4zNzQ0IDE3LjIzMjIgNi42MjU2NyAxNC4zMDMzIDMuNjk2NzNDMTEuMzc0NCAwLjc2NzgwMSA2LjYyNTYzIDAuNzY3ODAxIDMuNjk2NyAzLjY5NjczQzAuNzY3NzY3IDYuNjI1NjcgMC43Njc3NjcgMTEuMzc0NCAzLjY5NjcgMTQuMzAzM1oiIHN0cm9rZT0iIzg4ODg4OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTEuMTIxMyA2Ljg3ODY2TDYuODc4NyAxMS4xMjEzIiBzdHJva2U9IiM4ODg4ODgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuODc4NjYgNi44Nzg2NkwxMS4xMjEzIDExLjEyMTMiIHN0cm9rZT0iIzg4ODg4OCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzIwMV8yNTA1MSI+CjxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                cursor: pointer;
            }
        }


        .projects-list-empty {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .empty-title {
                font-weight: 700;
                font-size: 24px;
                line-height: 34px;
                color: $grey-color-dark;
            }

            .empty-subtitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: $grey-color-dark;
                margin-top: 20px;
            }
        }
    }
}