.FieldBalanceOverview {
    display: flex;
    flex-direction: column;

    .form-text-row {
        position: relative;

        &.openable {
            cursor: pointer;
            padding-right: 40px;

            &::after {
                content: '';
                width: 15px;
                height: 15px;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMTI1IDMuNzYxMjFMOC4wNyA3Ljk5OTY3TDMuMTI1IDEyLjIzODhMMy4xMjUgMTUuNUwxMS44NzUgNy45OTk2N0wzLjEyNSAwLjVMMy4xMjUgMy43NjEyMVoiIGZpbGw9IiMwMDE5MzUiLz4KPC9zdmc+Cg==');
                background-size: 15px 15px;
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                top: calc(50% - 7px);
                right: 20px;
                transform: rotate(90deg);
                transform-origin: center;

                @include respond-points(0, 1300) {
                    right: 15px;
                }
            }

            &.open {
                &::after {
                    transform: rotate(-90deg);
                }
            }
        }

    }

    .inputs-row {
        display: flex;
        gap: 25px 15px;
        margin-top: 20px;

        @include respond-points(0, 767) {
            gap: 15px 8px;
            margin-top: 6px;
        }

        .row-number {
            width: 30px;
            height: 30px;
            background-color: $grey-color-light;
            border: 1px solid $grey-color;
            color: #888;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 14px;
            line-height: normal;
            border-radius: 50%;
            margin-top: auto;
            margin-bottom: 8px;

            @include respond-points(0, 767) {
                display: none;
            }
        }

        .input-item {

            &.spread {
                flex: 1;
            }
        }
    }
}