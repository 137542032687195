.form-field {
    margin-bottom: 50px;

    &:last-of-type {
        margin-bottom: 0;
    }

    .form-text-row {
        display: flex;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: $sec-color-light;
        margin: 20px 0;

        @include respond-points(0, 1300) {
            padding: 8px 12px;
        }

        .row-label {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: $darkblue-color;
        }

        .row-value {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: $darkblue-color;
            margin-left: auto;
        }
    }

    .field-heading {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: $darkblue-color;
        margin-bottom: 20px;

        @include respond-points(0, 1300) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    .field-subheading {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $darkblue-color;
        margin-bottom: 20px;
    }


    .field-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: $darkblue-color;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        @include respond-points(0, 1300) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 10px;
        }

        &.title-blue {
            padding: 10px 20px;
            border-radius: 5px;
            background-color: $sec-color-light;

            @include respond-points(0, 1300) {
                padding: 8px 12px;
            }
        }
    }


    .field-subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $darkblue-color;

        @include respond-points(0, 767) {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 10px;
        }
    }

    .field-warning {
        display: flex;
        margin: 20px 0;
        padding: 15px 35px 15px 60px;
        border: 1px solid $primary-color;
        border-radius: 5px;
        color: $primary-color-dark;
        background-color: $primary-color-light;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.4;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDIyQzE3LjUyMjggMjIgMjIgMTcuNTIyOCAyMiAxMkMyMiA2LjQ3NzE1IDE3LjUyMjggMiAxMiAyQzYuNDc3MTUgMiAyIDYuNDc3MTUgMiAxMkMyIDE3LjUyMjggNi40NzcxNSAyMiAxMiAyMloiIHN0cm9rZT0iI0VGN0QwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEyLjAwOTggOEwxMi4wMDk4IDEyIiBzdHJva2U9IiNFRjdEMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMi4wMDk4IDE2TDExLjk5OTggMTYiIHN0cm9rZT0iI0VGN0QwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
        background-size: 24px 24px;
        background-position: left 20px center;
        background-repeat: no-repeat;

        @include respond-points(0, 767) {
            padding: 10px 25px 10px 45px;
            background-size: 20px 20px;
            background-position: left 15px center;
            font-size: 14px;
        }
    }

    .input-item {
        display: flex;
        flex-direction: column;
        position: relative;

        .input-label {
            display: flex;
            margin-bottom: 2px;

            .label-text {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: $darkblue-color;
            }
        }

        .field-input {
            padding: 10px 15px;
            color: $darkblue-color;
            border: 1.5px solid $grey-color;
            border-radius: 5px;
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            margin-top: 5px;

            &.multiselect {
                caret-color: transparent;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuOTA3OTU5TDcgNi45MDc5NkwxMyAwLjkwNzk1OSIgc3Ryb2tlPSIjMDAxOTM1IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
                background-size: 12px 6px;
                background-position: right 20px center;
                background-repeat: no-repeat;
                cursor: pointer;
            }

            &.warning {
                border-color: $primary-color;
            }

            &::placeholder {
                font-family: $font;
                color: $grey-color;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
            }

            &.multiselect:focus {
                outline: none;
            }

            &:not(.multiselect):focus {
                border-color: $sec-color;
                outline: none;
            }

            &:disabled {
                background-color: $grey-color-light;
            }

            &.disabled {
                pointer-events: none;
                background-color: $grey-color-light;
            }
        }


        .field-textarea {
            padding: 10px 15px;
            color: $darkblue-color;
            border: 1.5px solid $grey-color;
            font-family: $font;
            border-radius: 5px;
            width: 100%;
            height: 200px;
            resize: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;

            &.warning {
                border-color: $primary-color;
            }

            &::placeholder {
                font-family: $font;
                color: $grey-color;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
            }

            &:focus {
                border-color: $sec-color;
                outline: none;
            }

            &:disabled {
                background-color: $grey-color-light;
            }
        }

        .radio-input-list {
            display: flex;
            flex-direction: column;

            @include respond-points(0, 767) {
                flex-direction: row;
            }

            .radio-row {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                color: $darkblue-color;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .radio-input {
                    margin-right: 10px;
                }

                @include respond-points(0, 767) {
                    margin: 0 15px 0 0;
                }
            }
        }


        .radio-input[type="radio"] {
            appearance: none;
            background-color: #fff;
            font-family: $font;
            color: $grey-color;
            width: 16px;
            height: 16px;
            border: 1px solid $grey-color;
            border-radius: 50%;
            position: relative;
            display: flex;
            justify-content: center;
            cursor: pointer;

            &:checked {
                border: 1px solid $sec-color;

                &::before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    width: 8px;
                    height: 8px;
                    background-color: $sec-color;
                    border-radius: 50%;
                }
            }

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .input-checkbox {
            min-width: 20px;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border: 1.5px solid $grey-color;
            display: flex;
            align-items: center;
            margin-right: 8px;
            cursor: pointer;

            &.checked {
                background-color: $sec-color;
                border: 1.5px solid $sec-color;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzMgMUw0LjU0MTY3IDguNzkxNjdMMSA1LjI1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
                background-size: 11px auto;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        .input-error {
            font-size: 14px;
            line-height: 1.2;
            font-weight: 400;
            margin-top: 2px;
            color: $red-color;
        }

        .suggestions {
            position: absolute;
            top: 76px;
            left: 0;
            width: 100%;
            max-height: 200px;
            object-position: top center;
            z-index: 1;
            padding: 10px 13px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid $sec-color;
            overflow-y: auto;

            @include respond-points(0, 1300) {
                padding: 5px;
            }

            &.no-labels {
                top: 53px;
            }

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: #00000010;
                margin-right: 1px;
            }

            &::-webkit-scrollbar-thumb {
                background: $sec-color;
                border-radius: 10px;
            }

            &.multiselect {
                li {
                    padding-left: 30px;

                    &.selected {
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjY2NjMgMy41TDUuMjQ5NjcgOS45MTY2N0wyLjMzMzAxIDciIHN0cm9rZT0iIzAwOEFDRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
                        background-position: left 8px center;
                        background-size: 14px 14px;
                        background-repeat: no-repeat;
                        background-color: $sec-color-light;
                    }
                }
            }

            li {
                list-style: none;
                margin-bottom: 12px;
                padding: 8px 17px;
                color: $darkblue-color;
                border-radius: 5px;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                cursor: pointer;

                @include respond-points(0, 1300) {
                    padding: 8px;
                }

                &:hover {
                    background-color: $sec-color-light;
                    color: $sec-color;
                    text-decoration: underline;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .input-counter {
            font-size: 14px;
            color: $darkblue-color;
            margin-top: 2px;
            font-variant-numeric: tabular-nums;

            &.limmit {
                color: $red-color;
            }
        }

        &.narrow {
            max-width: 400px;
        }

        &.signature-input {
            display: flex;
            margin-top: 20px;

            canvas {
                color: $darkblue-color;
                border: 1.5px solid $grey-color;
                border-radius: 5px;
                font-weight: 400;
                width: 100%;
                font-size: 16px;
                line-height: 26px;
            }

            &.warning {
                canvas {
                    border-color: $primary-color;
                }
            }

            .signature-clear {
                margin-left: auto;
                font-size: 14px;
                color: $darkblue-color;
                cursor: pointer;
                margin-top: 5px;

                &:hover {
                    text-decoration: underline;
                }
            }

            &.disabled {
                pointer-events: none;
            }
        }
    }


    .field-content {

        p,
        li,
        a {
            font-size: 16px;
            line-height: 1.4;
        }

        p,
        ul,
        ol {
            margin: 5px 0 10px;
        }

        ul,
        ol {
            padding-left: 20px;
        }

        table {
            width: 100%;
            margin: 10px 0;

            @include respond-points(0, 767) {
                display: block;
                overflow-x: auto;
            }

            tr {
                &:nth-child(odd) {
                    background-color: #00000008;
                }

                td {
                    padding: 10px;
                }
            }
        }
    }

}