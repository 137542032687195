.FieldDownload {


    .download-action {
        display: flex;
        align-items: center;
        margin-top: 30px;
    }

    a {
        text-decoration: none;
        display: flex;
    }

    .download-document {
        width: 220px;
    }

    .last-download {
        margin-left: 20px;
        font-size: 16px;
        color: $grey-color-dark;
        width: -webkit-fill-available;
    }

}