.btn-main {
    padding: 8px 15px;
    background-color: $primary-color;
    border: 2px solid $primary-color;
    font-family: $font;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    cursor: pointer;
    transition: $tr background-color, $tr border;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: fit-content;
    user-select: none;

    &:hover {
        background-color: $primary-color-hover;
        border: 2px solid $primary-color-hover;
    }

    @include respond-points(0, 767) {
        padding: 8px 8px;
        font-size: 16px;
        line-height: 18px;
    }

    &::first-letter {
        text-transform: uppercase;
    }


    &.arrow-back {
        display: flex;
        align-items: center;

        &::before {
            content: '';
            width: 6px;
            height: 9px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNS4zMzMzNyA2LjI2MDY5TDIuNjk2MDQgNC4wMDAxOEw1LjMzMzM3IDEuNzM5MzFMNS4zMzMzNyAwTDAuNjY2NzA2IDQuMDAwMThMNS4zMzMzNyA4TDUuMzMzMzcgNi4yNjA2OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 5px;
        }
    }


    &.arrow-forward {
        display: flex;
        align-items: center;

        &::after {
            transform: rotate(180deg);
            content: '';
            width: 6px;
            height: 9px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNS4zMzMzNyA2LjI2MDY5TDIuNjk2MDQgNC4wMDAxOEw1LjMzMzM3IDEuNzM5MzFMNS4zMzMzNyAwTDAuNjY2NzA2IDQuMDAwMThMNS4zMzMzNyA4TDUuMzMzMzcgNi4yNjA2OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: 5px;
        }
    }

    &.blue {
        background-color: $sec-color;
        border: 2px solid $sec-color;

        &:hover {
            background-color: $sec-color-hover;
            border: 2px solid $sec-color-hover;
        }
    }

    &.disabled {
        pointer-events: none;
        background-color: $grey-color;
        border: 2px solid $grey-color;
    }

    &.plus {
        display: flex;
        align-items: center;

        &::after {
            content: '';
            width: 12px;
            height: 12px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIxM18zODEpIj4KPHBhdGggZD0iTTYgMTFDOC43NjE0MiAxMSAxMSA4Ljc2MTQyIDExIDZDMTEgMy4yMzg1OCA4Ljc2MTQyIDEgNiAxQzMuMjM4NTggMSAxIDMuMjM4NTggMSA2QzEgOC43NjE0MiAzLjIzODU4IDExIDYgMTFaIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYgNFY4IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQgNkg4IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yMTNfMzgxIj4KPHJlY3Qgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: 8px;
        }
    }

    &.download {
        display: flex;
        align-items: center;

        &::after {
            content: '';
            width: 12px;
            height: 12px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjUgNy41VjkuNUMxMC41IDkuNzY1MjIgMTAuMzk0NiAxMC4wMTk2IDEwLjIwNzEgMTAuMjA3MUMxMC4wMTk2IDEwLjM5NDYgOS43NjUyMiAxMC41IDkuNSAxMC41SDIuNUMyLjIzNDc4IDEwLjUgMS45ODA0MyAxMC4zOTQ2IDEuNzkyODkgMTAuMjA3MUMxLjYwNTM2IDEwLjAxOTYgMS41IDkuNzY1MjIgMS41IDkuNVY3LjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMy41IDVMNiA3LjVMOC41IDUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNiA3LjVWMS41IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-left: auto;
        }
    }


    &.save-data {
        width: 120px;
        justify-content: center;
        min-height: 48px;

        @include respond-points(0, 767) {
            width: 80px;
            min-height: 38px;
        }

        &.save-data-loading {
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kOiBub25lOyBkaXNwbGF5OiBibG9jazsgc2hhcGUtcmVuZGVyaW5nOiBhdXRvOyIgd2lkdGg9IjIwMHB4IiBoZWlnaHQ9IjIwMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiPgo8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiBmaWxsPSJub25lIiBzdHJva2U9IiM4ODg4ODgiIHN0cm9rZS13aWR0aD0iMTAiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHRyYW5zZm9ybT0icm90YXRlKDE1LjYyMTEgNTAgNTApIj4KICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGR1cj0iMXMiIHZhbHVlcz0iMCA1MCA1MDszNjAgNTAgNTAiIGtleVRpbWVzPSIwOzEiPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+Cjwvc3ZnPg==');
            background-size: 25px 25px;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            background-color: $grey-color;
            border: 2px solid $grey-color;
        }
    }

}


.btn-sec {
    padding: 8px 15px;
    background-color: #fff;
    border: 2px solid $grey-color;
    font-family: $font;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    color: $darkblue-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    cursor: pointer;
    transition: $tr background-color;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: fit-content;
    user-select: none;

    &:hover {
        background-color: $grey-color-light;
    }

    @include respond-points(0, 767) {
        padding: 8px 8px;
        font-size: 16px;
        line-height: 18px;
    }

    &::first-letter {
        text-transform: uppercase;
    }


    &.save-data {
        width: 120px;
        justify-content: center;
        min-height: 48px;

        @include respond-points(0, 767) {
            width: 80px;
            min-height: 38px;
        }

        &.save-data-loading {
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kOiBub25lOyBkaXNwbGF5OiBibG9jazsgc2hhcGUtcmVuZGVyaW5nOiBhdXRvOyIgd2lkdGg9IjIwMHB4IiBoZWlnaHQ9IjIwMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiPgo8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiBmaWxsPSJub25lIiBzdHJva2U9IiM4ODg4ODgiIHN0cm9rZS13aWR0aD0iMTgiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHRyYW5zZm9ybT0icm90YXRlKDE1LjYyMTEgNTAgNTApIj4KICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGR1cj0iMXMiIHZhbHVlcz0iMCA1MCA1MDszNjAgNTAgNTAiIGtleVRpbWVzPSIwOzEiPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+Cjwvc3ZnPg==');
            background-size: 25px 25px;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            background-color: $grey-color;
            border: 2px solid $grey-color;
        }
    }

    &.disabled {
        pointer-events: none;
        background-color: $grey-color;
        border: 2px solid $grey-color;
        color: #fff;
    }


}