.popup-main {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 25, 53, 0.33);
    z-index: 10;


    .popup-form {
        padding: 30px;
        background-color: #fff;
        border: 1px solid $grey-color;
        border-radius: 5px;
        width: 100%;
        max-width: 580px;
        position: relative;
        margin: 0 10px;

        @include respond-points(0, 767) {
            padding: 20px;
        }

        .close-popup {
            position: absolute;
            top: 30px;
            right: 30px;
            width: 19px;
            height: 19px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIxM18zMTg0KSI+CjxwYXRoIGQ9Ik0zLjkwMjA3IDE1LjU5OEM2Ljk5MzcyIDE4LjY4OTYgMTIuMDA2MyAxOC42ODk2IDE1LjA5NzkgMTUuNTk4QzE4LjE4OTYgMTIuNTA2MyAxOC4xODk2IDcuNDkzNzggMTUuMDk3OSA0LjQwMjEzQzEyLjAwNjMgMS4zMTA0OCA2Ljk5MzcyIDEuMzEwNDggMy45MDIwNyA0LjQwMjEzQzAuODEwNDIgNy40OTM3OCAwLjgxMDQyIDEyLjUwNjMgMy45MDIwNyAxNS41OThaIiBzdHJva2U9IiMwMDE5MzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMS43MzkyIDcuNzYwODZMNy4yNjA4MiAxMi4yMzkyIiBzdHJva2U9IiMwMDE5MzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik03LjI2MDgzIDcuNzYwODZMMTEuNzM5MiAxMi4yMzkyIiBzdHJva2U9IiMwMDE5MzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjEzXzMxODQiPgo8cmVjdCB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDAuNSkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;

            @include respond-points(0, 767) {
                top: 20px;
                right: 20px;
            }
        }

        .popup-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: $darkblue-color;
            margin-bottom: 30px;
        }

        .popup-subtitle {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: $darkblue-color;
        }

        .popup-input {
            margin-bottom: 30px;
        }

        .button-row {
            display: flex;
            margin-top: 30px;

            &.right {
                justify-content: flex-end;

                @include respond-points(0, 767) {
                    justify-content: center;
                }
            }

            .popup-btn {
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}