.FormStepView {
    display: flex;
    flex-direction: column;

    .step-view-main {
        display: flex;
        flex-direction: column;
        padding: 40px;

        @include respond-points(768, 1300) {
            padding: 30px;
        }

        @include respond-points(0, 767) {
            padding: 15px;
        }
    }

    .step-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 50px;
        margin-bottom: 30px;
        color: $darkblue-color;
        word-break: break-word;

        @include respond-points(768, 1300) {
            font-size: 26px;
            line-height: 34px;
        }

        @include respond-points(0, 767) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .step-subtitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 10px;
        color: $darkblue-color;

        @include respond-points(0, 1300) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    .step-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 40px;
        color: $darkblue-color;
    }

    .step-bottom-title {
        padding: 12px 24px;
        background-color: $grey-color-light;
        border-radius: 0 0 4px 4px;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: $grey-color-dark;
        word-break: break-word;

        @include respond-points(0, 1300) {
            padding: 10px 15px;
        }
    }
}