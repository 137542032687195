* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-variant-ligatures: none;
}

body {
    font-family: $font;

    &.no-scroll {
        overflow: hidden;
    }
}

.main-root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 40px;
    position: relative;

    @include respond-points(0, 1300) {
        padding: 0 20px;
    }
}

.container {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    position: relative;
    padding: 0 15px;

    @include respond-points(0, 1300) {
        padding: 0;
    }
}