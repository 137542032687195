.Main {
    display: flex;
    margin-top: 70px;

    @include respond-points(0, 1300) { 
        margin-top: 20px;
     }

    .container {
        display: flex;

        @include respond-points(0, 1300) {
            flex-direction: column-reverse;
        }
    }
}