.FormMain {
    display: flex;
    flex-direction: column;
    width: calc(100% - 310px);
    padding: 0 0 0 20px;
    margin: 0 0 100px 0;

    @include respond-points(0, 1300) {
        width: 100%;
        padding: 0;
    }
}